import { UseCase } from '@/core/base/use-case';
import { Observable } from 'rxjs';
import { IPreregister, IRegister } from '../models/waitlist.model';
import { WaitListRepository } from '../repositories/waitlist.repository';

export type LoderDataPreregister = IPreregister;
export type LoderDataRegister = IRegister;

export class PostPreregisterUseCase
    implements UseCase<LoderDataPreregister, any>
{
    constructor(private readonly repository: WaitListRepository) {}

    execute(param: LoderDataPreregister): Observable<any> {
        return this.repository.postPreregister(param);
    }
}

export class PostRegisterUseCase implements UseCase<LoderDataRegister, any> {
    constructor(private readonly repository: WaitListRepository) {}

    execute(param: LoderDataRegister): Observable<any> {
        return this.repository.postRegister(param);
    }
}
