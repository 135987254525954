import { PipesModule } from './../../../common/pipes/pipes.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CardComponent } from './card/card.component';
import { VgInputComponent } from './vg-input/vg-input.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { VgButtonComponent } from './vg-button/vg-button.component';
import { VgBadgeComponent } from './vg-badge/vg-badge.component';
import { VgProgressComponent } from './vg-progress/vg-progress.component';
import { VgCheckComponent } from './vg-check/vg-check.component';
import { SwiperComponent } from './swiper/swiper.component';
import { AutosizeModule } from 'ngx-autosize';
import { SwiperMessageComponent } from './swiper-message/swiper-message.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { DirectivesModule } from '../../../common/directives/directives.module';

@NgModule({
    declarations: [
        CardComponent,
        ModalWrapperComponent,
        SpinnerComponent,
        SwiperComponent,
        SwiperMessageComponent,
        VgBadgeComponent,
        VgButtonComponent,
        VgCheckComponent,
        VgInputComponent,
        VgProgressComponent,
    ],
    exports: [
        CardComponent,
        ModalWrapperComponent,
        SpinnerComponent,
        SwiperComponent,
        SwiperMessageComponent,
        VgBadgeComponent,
        VgButtonComponent,
        VgCheckComponent,
        VgInputComponent,
        VgProgressComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe,
        AutosizeModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forChild({
            extend: true,
        }),
        NgSelectModule,
        PipesModule,
        MatDatepickerModule,
        MatIconModule,
        DirectivesModule,
    ],
    providers: [
        provideNgxMask(),
        {
            provide: DateAdapter,
            deps: [MAT_DATE_LOCALE],
            useValue: new LuxonDateAdapter('es-MX', {
                useUtc: false,
                firstDayOfWeek: 1,
                defaultOutputCalendar: 'gregory',
            }),
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'dd LLLL yyyy',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}
