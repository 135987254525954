<vg-header [maintenance_status]="true"></vg-header>
<div class="maintenance-container">
    <div class="maintenance-content">
        <h2>{{ 'vg_maintenance_status_h2_title' | translate }}</h2>
        <h3>{{ 'vg_maintenance_status_h3_subtitle' | translate }}</h3>
        <img
            src="https://cdn-viva.gruposti.dev/vg/maintenance/maintenance.svg"
            alt=""
            class="mt-3 d-block"
        />
    </div>
</div>
<vg-footer></vg-footer>
