import { Injectable } from '@angular/core';
import { Observable, catchError, concat, filter, of, switchMap } from 'rxjs';
import { environment } from '@/env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WaitListRepository } from '@/core/domain/waitlist/repositories/waitlist.repository';
import {
    IPreregister,
    IRegister,
} from '@/core/domain/waitlist/models/waitlist.model';

@Injectable({
    providedIn: 'root',
})
export class WaitListImplRepository extends WaitListRepository {
    constructor(private readonly http: HttpClient) {
        super();
    }

    override postPreregister(data: IPreregister): Observable<any> {
        // TODO: MOVE LOGIC INTO A SERVICE
        const _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Channel': 'web',
        });

        return concat(
            this.http.post(
                environment.waitList.BASE_HOST +
                    environment.waitList.ENDPOINTS.preregister,
                data,
                { headers: _headers },
            ),
        ).pipe(
            filter((response: any) => response != null),
            switchMap((response: any) => {
                const configData = response;
                return of(configData as any);
            }),
            catchError((error) => {
                return of(error as any);
            }),
        );
    }

    override postRegister(data: IRegister): Observable<any> {
        // TODO: MOVE LOGIC INTO A SERVICE
        const _headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Channel': 'web',
        });

        return concat(
            this.http.post(
                environment.waitList.BASE_HOST +
                    environment.waitList.ENDPOINTS.register,
                data,
                { headers: _headers },
            ),
        ).pipe(
            filter((response: any) => response != null),
            switchMap((response: any) => {
                const configData = response;
                return of(configData as any);
            }),
            catchError((error) => {
                return of(error as any);
            }),
        );
    }
}
