import {
    Component,
    ViewEncapsulation,
    EventEmitter,
    Output,
    Input,
    OnInit,
} from '@angular/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const ANIMATIONS_CARD = [
    trigger('fadeInOut', [
        state(
            'void',
            style({
                opacity: 0,
                transform: 'translateY(-20px)',
            }),
        ),
        transition(':enter, :leave', [animate('0.3s ease-in-out')]),
    ]),
];

@Component({
    selector: 'vg-card-amount',
    templateUrl: './card-amount.component.html',
    styleUrls: ['./card-amount.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class CardAmountComponent implements OnInit {
    amounts$: number[] = [];
    min = 0;
    max = 0;
    step = 0;
    cardAmount = 500;
    @Output() newItemEvent = new EventEmitter<string>();

    @Output() selectedCardAmount = new EventEmitter();

    constructor() {
        this.min = 0;
    }

    public ngOnInit(): void {
        this.selectedCardAmount.emit(this.cardAmount);
    }
    public increaseAmount(amount: number) {
        if (this.cardAmount >= this.min && this.cardAmount < this.max) {
            this.cardAmount += amount;
            this.selectedCardAmount.emit(this.cardAmount);
        }
    }

    public decreaseAmount(amount: number) {
        if (this.cardAmount >= 501 && this.cardAmount <= this.max) {
            this.cardAmount -= amount;
            this.selectedCardAmount.emit(this.cardAmount);
        }
    }

    public setAmount(amount: number) {
        this.cardAmount = amount;
        this.selectedCardAmount.emit(this.cardAmount);
    }

    public nextButton() {
        this.newItemEvent.emit(this.cardAmount.toString());
    }
}
