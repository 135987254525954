import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';

import { WaitListDataModule } from '@/data/waitlist/waitlist-data.module';
import { CurrentProgressReducer } from '@/data/current-progress/store';
import { TranslationReducer } from '@/data/translation/store';
import { TranslationsDataModule } from '@/data/translation/translations-data.module';
import { hydrationMetaReducer } from '../data/persistence/hydration.reducer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ModalErrorDataModule } from '@/data/modal-error/modal-error-data.module';
import { ModalErrorReducer } from '@/data/modal-error/store/modal-error.reducer';

import localEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localEs, 'es');

@NgModule({
    declarations: [AppComponent],
    imports: [
        StoreModule.forRoot(
            {
                translations: TranslationReducer,
                currentProgress: CurrentProgressReducer,
                modalError: ModalErrorReducer,
            },
            { metaReducers: [hydrationMetaReducer] },
        ),
        TranslationsDataModule,
        ModalErrorDataModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es-mx',
        }),
        HttpClientModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
        NgSelectModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        WaitListDataModule,
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'es-mx' }],
    bootstrap: [AppComponent],
})
export class AppModule {}
