import { Injectable } from '@angular/core';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    first,
    of,
    switchMap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslationsRepository } from '../../core/domain/translation/repositories/translations.repository';
import { TranslationModel } from '../../core/domain/translation/models/translation.model';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { loadAction } from './store/translation.actions';
import { LanguageModel } from '@/core/domain/translation/models/language.model';
import { environment } from '../../environments/environment';
import { CdnService } from '@/common/cdn/cdn.service';

@Injectable({
    providedIn: 'root',
})
export class TranslationsImplRepository extends TranslationsRepository {
    constructor(
        // eslint-disable-next-line @ngrx/no-typed-global-store
        private readonly store: Store<{ translations: TranslationModel }>,
        private readonly http: HttpClient,
        private readonly translate: TranslateService,
    ) {
        super();
    }

    override getLanguague(): Observable<LanguageModel> {
        const paths = window.location.href.split('/');
        let currentLanguage = environment.allowedLanguages[0];
        paths.forEach((element) => {
            environment.allowedLanguages.forEach((language) => {
                if (language === element) {
                    currentLanguage = language;
                }
            });
        });
        return of({ language: currentLanguage as 'es-mx' | 'en-us' });
    }

    override load(lang: string): Observable<TranslationModel> {
        return concat(
            // eslint-disable-next-line @ngrx/prefer-selector-in-select
            this.store.select('translations').pipe(first()),
            this.http.get(`assets/translations/${lang}.json`),
        ).pipe(
            filter(
                (response) =>
                    Object.keys(response).length > 0 &&
                    response.constructor === Object,
            ),
            switchMap((response: unknown) => {
                this.translate.use(lang);
                const translations =
                    typeof response === 'string'
                        ? JSON.parse(response)
                        : response;
                this.translate.setTranslation(lang, translations);
                this.store.dispatch(loadAction({ value: translations }));
                return of(response as TranslationModel);
            }),
            catchError(() => EMPTY),
        );
    }
}
