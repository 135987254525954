import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LanguageGuard } from './language.guard';

const routes: Routes = [
    {
        path: ':language/vivafan',
        component: AppComponent,
        loadChildren: () =>
            import('./pages/pages.module').then((c) => c.PagesModule),
        canActivate: [LanguageGuard],
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/es-mx/vivafan',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 70],
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
