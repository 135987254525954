import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '../ui/ui.module';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalSuccessComponent } from './modal-success/modal-success.component';
import {
    NgbAccordionModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { CardAmountComponent } from './card-amount/card-amount.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DirectivesModule } from '../../../common/directives/directives.module';
import { NgxMaskPipe } from 'ngx-mask';
import { ModalThreedsComponent } from './modal-threeds/modal-threeds.component';
import { ModalLoaderComponent } from './modal-loader/modal-loader.component';
import { LottieModule } from 'ngx-lottie';
import { QuestionsComponent } from './questions/questions.component';
import { WaitlistConfirmationComponent } from './waitlist-confirmation/waitlist-confirmation.component';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        CardAmountComponent,
        FooterComponent,
        HeaderComponent,
        MaintenanceComponent,
        ModalErrorComponent,
        ModalSuccessComponent,
        ModalThreedsComponent,
        ModalLoaderComponent,
        QuestionsComponent,
        WaitlistConfirmationComponent,
    ],
    exports: [
        CardAmountComponent,
        FooterComponent,
        HeaderComponent,
        MaintenanceComponent,
        ModalErrorComponent,
        ModalSuccessComponent,
        ModalThreedsComponent,
        ModalLoaderComponent,
        QuestionsComponent,
    ],
    imports: [
        UiModule,
        CommonModule,
        NgbAccordionModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        TranslateModule.forChild({
            extend: true,
        }),
        PipesModule,
        RouterModule,
        DirectivesModule,
        NgxMaskPipe,
        LottieModule.forRoot({ player: playerFactory }),
    ],
})
export class ComponentsModule {}
