<div
    [ngClass]="{ 'form-container-icon': icon !== null }"
    class="form-container"
>
    <label [for]="id">{{ label }}</label>
    <img
        class="is-valid-card-img"
        *ngIf="cardTypeImage !== '' && ngControl.valid"
        [src]="cardTypeImage"
        alt=""
    />
    <div *ngIf="icon !== null" class="form-icon" [ngClass]="getDynamiClass()">
        <img
            class="form-icon one-icon"
            [src]="'/vg/images/' + icon + '.svg' | cdn"
            alt=""
        />
        <img
            class="form-icon two-icon"
            [src]="'/vg/images/' + iconInactive + '.svg' | cdn"
            alt=""
        />
    </div>
    <ng-template [ngIf]="isDatepicker">
        <input
            matInput
            #inputDatePicker
            [(ngModel)]="value"
            [type]="type === 'card' ? 'text' : type"
            [ngClass]="getDynamiClass()"
            class="form-control"
            [id]="id"
            [placeholder]="placeholder"
            [disabled]="disabled"
            (blur)="onBlur()"
            (input)="onInput($event)"
            [min]="min"
            [max]="max"
            [attr.autocomplete]="autocomplete"
            [attr.autocorrect]="autocorrect"
            [disabled]="disabled"
            (focus)="onFocusInputDatepicker()"
            [matDatepicker]="datepicker"
            (dateChange)="onDateChange($event)"
        />
        <mat-datepicker-toggle
            matSuffix
            (click)="onClickDatepickerToggle()"
            [for]="datepicker"
        >
            <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker
            [calendarHeaderComponent]="customHeaderComponent"
            [restoreFocus]="false"
            (closed)="onClosedDatepicker()"
            (opened)="onOpenedDatepicker()"
            #datepicker
        ></mat-datepicker>
    </ng-template>
    <input
        *ngIf="!isTextarea && !isSelect && !isDatepicker"
        [(ngModel)]="value"
        [type]="
            type === 'card' ? 'tel' : type === 'expirationDate' ? 'tel' : type
        "
        [ngClass]="getDynamiClass()"
        class="form-control"
        [id]="id"
        [placeholder]="placeholder"
        [mask]="mask"
        [disabled]="disabled"
        (blur)="onBlur()"
        (input)="onInput($event)"
        [attr.min]="min"
        [attr.max]="max"
        [attr.autocomplete]="autocomplete"
        [attr.autocorrect]="autocorrect"
        [attr.maxlength]="maxlength"
        [attr.inputmode]="inputmode"
        [disabled]="disabled"
    />
    <textarea
        [autosize]="false"
        *ngIf="isTextarea"
        [(ngModel)]="value"
        class="form-control"
        [id]="id"
        [placeholder]="placeholder"
        [mask]="mask"
        [disabled]="disabled"
        [ngClass]="getDynamicTextAreaClass()"
        (blur)="onBlur()"
        [attr.min]="min"
        maxlength="45"
        [attr.max]="max"
        [attr.autocomplete]="autocomplete"
        [attr.autocorrect]="autocorrect"
        [disabled]="disabled"
        [readonly]="readonly"
    ></textarea>
    <ng-select
        [ngClass]="getDynamiClass() + ' ' + uniqClassSelect"
        [disabled]="disabled"
        *ngIf="isSelect"
        [items]="items"
        bindLabel="name"
        bindValue="id"
        [placeholder]="placeholder"
        (blur)="onBlurSelect()"
        [(ngModel)]="value"
        [clearable]="false"
        [notFoundText]="'vg-purchase-payment-select-not-found' | translate"
    >
    </ng-select>
    <ng-template ngFor let-itemError [ngForOf]="getControlErrors()">
        <div class="feedback invalid-feedback">
            <span [ngSwitch]="itemError?.error">
                <ng-container
                    *ngSwitchCase="'minlength'"
                    [ngTemplateOutlet]="minLengthError"
                    [ngTemplateOutletContext]="{
                        $implicit: itemError
                    }"
                ></ng-container>
                <ng-container
                    *ngSwitchCase="'matchedValues'"
                    [ngTemplateOutlet]="matchedValuesError"
                    [ngTemplateOutletContext]="{
                        $implicit: itemError
                    }"
                ></ng-container>
                <ng-container
                    *ngSwitchDefault
                    [ngTemplateOutlet]="genericError"
                    [ngTemplateOutletContext]="{
                        $implicit: itemError
                    }"
                ></ng-container>
            </span>
        </div>
    </ng-template>
    <ng-template #genericError let-itemError>
        {{ 'wl_' + flowPage + '_input_' + itemError?.error | translate }}
    </ng-template>
    <ng-template #minLengthError let-itemError>
        {{
            'wl_' + flowPage + '_input_' + itemError?.error
                | translate
                | replace
                    : {
                          actualLength: itemError?.reason?.actualLength,
                          requiredLength: itemError?.reason?.requiredLength
                      }
        }}
    </ng-template>
    <ng-template #matchedValuesError let-itemError>
        {{
            'wl_' + flowPage + '_input_' + itemError?.error
                | translate
                | replace
                    : {
                          currentValue: itemError?.reason?.currentValue,
                          toMistmachValue: itemError?.reason?.toMistmachValue
                      }
        }}
    </ng-template>
</div>
