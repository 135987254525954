import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from './store/modal-error.actions';
import { ModalErrorData } from './entities/modal-error-data.entity';
import { ModalErrorRepository } from '@/core/domain/modal-error/repositories/modal-error.repository';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalErrorImplRepository extends ModalErrorRepository {
    constructor(private readonly store: Store) {
        super();
    }

    override setErrorToModal(payload: ModalErrorData) {
        const data = {
            isOpen: true,
            error: payload.error,
            description: payload.description,
        };

        this.store.dispatch(actions.openModalError({ data }));
        return of();
    }

    override closeErrorModal() {
        this.store.dispatch(actions.closeModalError());
        return of();
    }
}
