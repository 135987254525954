import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '@/common/url/url.service';
import { TranslateService } from '@ngx-translate/core';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';
import {
    LoderDataPreregister,
    LoderDataRegister,
    PostPreregisterUseCase,
    PostRegisterUseCase,
} from '../../../../core/domain/waitlist/usecases/postWaitList-loader.usecase';

@Component({
    selector: 'vg-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class QuestionsComponent implements OnInit {
    public isButtonEnabled = false;
    public sendForm: FormGroup;
    public enabledBtnNext = false;
    public hasSubscription = null;
    public timesTravelYear = null;

    public isEmailRegistered = false;
    public isErrorPreregister = false;

    isLoadingBtn: boolean;
    heightInit = 0;
    optionsHasSubscription = [
        {
            id: 'YesIAmVivaFan',
            name: this.translate.instant(
                'wl_questions_form_option_has_subscription_yes_iam_vivafan',
            ),
        },
        {
            id: 'ImNotVivaFan',
            name: this.translate.instant(
                'wl_questions_form_option_has_subscription_im_not_vivaFan',
            ),
        },
        {
            id: 'IWasAMemberPreviously',
            name: this.translate.instant(
                'wl_questions_form_option_has_subscription_iwas_a_member_previously',
            ),
        },
    ];

    optionsTimesTravelYear = [
        {
            id: 'Between1And2',
            name: this.translate.instant(
                'wl_questions_form_option_times_travel_year_between_1and2',
            ),
        },
        {
            id: 'Between3And4',
            name: this.translate.instant(
                'wl_questions_form_option_times_travel_year_between_3and4',
            ),
        },
        {
            id: 'MoreThan5Times',
            name: this.translate.instant(
                'wl_questions_form_option_times_travel_year_more_than_5times',
            ),
        },
    ];

    ngOnInit(): void {
        const width = document.body.scrollWidth;
        this.heightInit =
            width <= 500
                ? document.body.scrollHeight + 230
                : document.body.scrollHeight + 100;
        window.parent.postMessage(
            {
                action: 'resizeIframe',
                height: width <= 500 ? this.heightInit : this.heightInit,
            },
            '*',
        );
    }

    constructor(
        private translate: TranslateService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly urlService: UrlService,
        private readonly postPreregisterUseCase: PostPreregisterUseCase,
        private readonly postRegisterUseCase: PostRegisterUseCase,
    ) {
        this.isLoadingBtn = false;
        this.sendForm = this.formBuilder.group({
            firstName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                    this.forbiddenCharactersValidatorFirstName(/["´?.¡-]/),
                ]),
            ],
            lastName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                    this.forbiddenCharactersValidatorLastName(/["´?.¡-]/),
                ]),
            ],
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        '^(?=.{1,63}@)[a-zA-Z0-9_-]{1,63}([.][a-zA-Z0-9_-]{0,62})?@[a-zA-Z0-9_]{1,63}([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,63}$',
                    ),
                    this.isRegisteredValidatorEmail(),
                    this.isErrorPreregisterValidatorEmail(),
                ]),
            ],
            hasSubscription: ['', Validators.compose([Validators.required])],
            timesTravelYear: ['', Validators.compose([Validators.required])],
        });

        // Validation for firstName make first letter of each word capital
        this.sendForm.get('firstName')?.valueChanges.subscribe((value) => {
            if (typeof value === 'string' && value.length > 0) {
                const capitalizedValue = value
                    .toLowerCase()
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                this.sendForm.patchValue(
                    { firstName: capitalizedValue },
                    { emitEvent: false },
                );
            }
        });
        // Validation for lastName make first letter of each word capital
        this.sendForm.get('lastName')?.valueChanges.subscribe((value) => {
            if (typeof value === 'string' && value.length > 0) {
                const capitalizedValue = value
                    .toLowerCase()
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                this.sendForm.patchValue(
                    { lastName: capitalizedValue },
                    { emitEvent: false },
                );
            }
        });
    }
    // Validation for prohibited characters in firstName
    forbiddenCharactersValidatorFirstName(forbiddenChars: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = forbiddenChars.test(control.value);
            return forbidden
                ? { forbiddenChars: { value: control.value } }
                : null;
        };
    }

    // Validation for prohibited characters in lastName
    forbiddenCharactersValidatorLastName(forbiddenChars: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = forbiddenChars.test(control.value);
            return forbidden
                ? { forbiddenChars: { value: control.value } }
                : null;
        };
    }

    // Validation for is registered email in waitlist
    isRegisteredValidatorEmail(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.isEmailRegistered
                ? { emailIsRegistered: { value: control.value } }
                : null;
        };
    }

    // Validation for is registered email in waitlist
    isErrorPreregisterValidatorEmail(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.isErrorPreregister
                ? { preregisterError: { value: control.value } }
                : null;
        };
    }

    validateInputsResize() {
        const emailControlValue = this.sendForm.get('email');
        const firstNameControlValue = this.sendForm.get('firstName');
        const lastNameControlValue = this.sendForm.get('lastName');
        const hasSubscriptionControlValue =
            this.sendForm.get('hasSubscription');
        const timesTravelYearControlValue =
            this.sendForm.get('timesTravelYear');

        let countResize = 0;
        const width = document.body.scrollWidth;

        if (emailControlValue) {
            emailControlValue.updateValueAndValidity();

            if (emailControlValue.errors && emailControlValue.touched) {
                countResize = countResize + 15;
            }
        }

        if (firstNameControlValue) {
            firstNameControlValue.updateValueAndValidity();

            if (firstNameControlValue.errors && firstNameControlValue.touched) {
                countResize = countResize + 15;
            }
        }

        if (lastNameControlValue) {
            lastNameControlValue.updateValueAndValidity();

            if (lastNameControlValue.errors && lastNameControlValue.touched) {
                countResize = countResize + 15;
            }
        }

        if (hasSubscriptionControlValue) {
            hasSubscriptionControlValue.updateValueAndValidity();

            if (
                hasSubscriptionControlValue.errors &&
                hasSubscriptionControlValue.touched
            ) {
                countResize = countResize + 15;
            }
        }

        if (timesTravelYearControlValue) {
            timesTravelYearControlValue.updateValueAndValidity();

            if (
                timesTravelYearControlValue.errors &&
                timesTravelYearControlValue.touched
            ) {
                countResize = countResize + 15;
            }
        }

        window.parent.postMessage(
            {
                action: 'resizeIframe',
                height:
                    width <= 500
                        ? this.heightInit + countResize
                        : this.heightInit + countResize,
            },
            '*',
        );
    }

    preregisterExect() {
        const emailControlValue = this.sendForm.get('email');

        if (emailControlValue) {
            emailControlValue.markAsTouched();
            emailControlValue.updateValueAndValidity();
            if (
                !emailControlValue.errors ||
                (emailControlValue.errors &&
                    emailControlValue.errors['emailIsRegistered'] &&
                    emailControlValue.errors['emailIsRegistered'].value) ||
                (emailControlValue.errors &&
                    emailControlValue.errors['preregisterError'] &&
                    emailControlValue.errors['preregisterError'].value)
            ) {
                this.submitPreregister({ email: emailControlValue?.value });
            }
        }
    }

    private submitPreregister(data: LoderDataPreregister): void {
        const emailControlValue = this.sendForm.get('email');

        this.postPreregisterUseCase.execute(data).subscribe((data) => {
            if (data.error) {
                this.isErrorPreregister = true;
                this.isEmailRegistered = false;
            } else if (data.type === 'SUCCESS' && data.action === 'CONTINUE') {
                this.isEmailRegistered = data.data.isRegistered;
                this.isErrorPreregister = false;
            } else {
                this.isErrorPreregister = true;
                this.isEmailRegistered = false;
            }
            if (emailControlValue) {
                emailControlValue.markAsTouched();
                emailControlValue?.updateValueAndValidity();
            }
        });
        this.validateInputsResize();
    }

    registerExect() {
        const dataForm = this.sendForm.value;
        if (this.sendForm.valid && !this.isEmailRegistered) {
            this.isLoadingBtn = true;
            this.submitRegister(dataForm);
        } else if (this.sendForm.valid && this.isEmailRegistered) {
            this.isLoadingBtn = false;
            this.urlService
                .format('waitlist-confirm')
                .subscribe((url) => this.router.navigateByUrl(url));
        }
    }

    private submitRegister(data: LoderDataRegister): void {
        this.postRegisterUseCase.execute(data).subscribe((data) => {
            if (data.error) {
                const errorCode = data.error.code;
                console.log('[ERROR]', errorCode);
                this.isLoadingBtn = false;
            } else if (data.type === 'SUCCESS' && data.action === 'CONTINUE') {
                this.isLoadingBtn = false;
                this.urlService
                    .format('waitlist-confirm')
                    .subscribe((url) => this.router.navigateByUrl(url));
            } else {
                const errorCode = data.error.code;
                console.log('[ERROR]', errorCode);
                this.isLoadingBtn = false;
            }
        });
    }
}
