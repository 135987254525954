import { Component } from '@angular/core';

@Component({
  selector: 'vg-waitlist-confirmation',
  templateUrl: './waitlist-confirmation.component.html',
  styleUrls: ['./waitlist-confirmation.component.scss']
})
export class WaitlistConfirmationComponent {

}
