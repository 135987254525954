import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { Component, Input } from '@angular/core';
import { BehaviorSubject, catchError, of, switchMap } from 'rxjs';
import { environment as env } from '@/env/environment';
import { Store } from '@ngrx/store';

@Component({
    selector: 'vg-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    public isLoading = false;
    public isLogged = false;
    public user$ = new BehaviorSubject({ name: '' });
    public showSettings = false;
    public currentLanguage: string | null = null;
    public showLogout = false;
    @Input() maintenance_status?: boolean = false;

    constructor(
        private readonly store: Store,
        private readonly getLanguage: TranslationsGetLanguageUseCase,
    ) {
        this.getLanguage
            .execute()
            .subscribe(
                (response) => (this.currentLanguage = response.language),
            );
    }

    onLogOut(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    public showUserSettings(): void {
        this.showSettings = !this.showSettings;
        if (this.showLogout) {
            this.showLogout = !this.showLogout;
        }
    }

    public showLogoutSettings(): void {
        this.showLogout = !this.showLogout;
        if (this.showSettings) {
            this.showSettings = !this.showSettings;
        }
    }

    public onClickOutside(): void {
        this.showSettings = false;
        this.showLogout = false;
    }

    public onClickChangeLang(lang: string): void {
        if (env.allowedLanguages.includes(lang)) {
            const regExpValue = env.allowedLanguages.join('|');
            const languagePathRegExp = new RegExp(regExpValue);

            window.location.replace(
                window.location.href.replace(languagePathRegExp, lang),
            );
        }
    }
}
