<!-- Slider main container -->
<div class="swiper-message">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide" value="¡Feliz cumpleaños!">
            ¡Feliz cumpleaños!
        </div>
        <div class="swiper-slide" value="¡Feliz aniversario!">
            ¡Feliz aniversario!
        </div>
        <div class="swiper-slide" value="¡Feliz viaje!">¡Feliz viaje!</div>
        <div class="swiper-slide" value="¡Feliz cumpleaños!">
            ¡Feliz cumpleaños!
        </div>
        <div class="swiper-slide" value="¡Feliz aniversario!">
            ¡Feliz aniversario!
        </div>
        <div class="swiper-slide" value="¡Feliz viaje!">¡Feliz viaje!</div>
    </div>
</div>

<div class="swiper-message-next">
    <img
        src="assets/images/circle-arrow-right.svg"
        alt="arrow_green"
        class="card-redeem-img"
    />
</div>
