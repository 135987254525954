import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(
        private readonly getLanguageUseCase: TranslationsGetLanguageUseCase,
    ) {}
    public format(resource: string): Observable<string> {
        return this.getLanguageUseCase.execute().pipe(
            switchMap((response) => {
                return of(
                    [
                        '',
                        response.language,
                        'vivafan',
                        resource.replace(/^\/+/, ''),
                    ].join('/'),
                );
            }),
        );
    }
}
