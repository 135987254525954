<section class="content-banner-form">
    <div class="logo">
        <img
            src="../../../../assets/images/viva-fan-logo.svg"
            alt=""
            class="logo-desk"
        />
    </div>
</section>
<div class="container-form-waitlist" [@fadeInOut]>
    <div class="container">
        <p class="swiper-text">
            {{ 'waitlist_form_subtitle_text' | translate }}
        </p>
    </div>
    <div class="col-12">
        <form [formGroup]="sendForm">
            <vg-input
                label="{{ 'waitlist_form_label_email' | translate }}"
                placeholder="{{
                    'waitlist_form_placeholder_email' | translate
                }}"
                formControlName="email"
                [flowPage]="'register_send'"
                [type]="'email'"
                [autocomplete]="'off'"
                (focusout)="preregisterExect()"
            ></vg-input>
            <vg-input
                label="{{ 'waitlist_form_label_firstname' | translate }}"
                placeholder="{{
                    'waitlist_form_placeholder_firstname' | translate
                }}"
                [disabled]="this.isEmailRegistered"
                formControlName="firstName"
                [flowPage]="'register_send'"
                [autocomplete]="'off'"
                [maxlength]="'80'"
                (focusout)="validateInputsResize()"
            ></vg-input>
            <vg-input
                label="{{ 'waitlist_form_label_lastname' | translate }}"
                placeholder="{{
                    'waitlist_form_placeholder_lastname' | translate
                }}"
                [disabled]="this.isEmailRegistered"
                formControlName="lastName"
                [flowPage]="'register_send'"
                [autocomplete]="'off'"
                [maxlength]="'80'"
                (focusout)="validateInputsResize()"
            ></vg-input>
            <vg-input
                label="{{ 'waitlist_form_label_has_subscription' | translate }}"
                placeholder="{{
                    'waitlist_form_placeholder_has_subscription' | translate
                }}"
                [disabled]="this.isEmailRegistered"
                [isSelect]="true"
                [(ngModel)]="hasSubscription"
                [formControlName]="'hasSubscription'"
                [flowPage]="'register_send'"
                [items]="optionsHasSubscription"
                (focusout)="validateInputsResize()"
            ></vg-input>
            <vg-input
                label="{{
                    'waitlist_form_label_times_travel_year' | translate
                }}"
                placeholder="{{
                    'waitlist_form_placeholder_times_travel_year' | translate
                }}"
                [disabled]="this.isEmailRegistered"
                [isSelect]="true"
                [(ngModel)]="timesTravelYear"
                [formControlName]="'timesTravelYear'"
                [flowPage]="'register_send'"
                [items]="optionsTimesTravelYear"
                (focusout)="validateInputsResize()"
            ></vg-input>
        </form>
        <div class="container-btn-interested">
            <vg-button
                [id]="'btn-confirm-send'"
                [style]="'secondary'"
                [loading]="isLoadingBtn"
                [disabled]="!this.sendForm.valid"
                (click)="registerExect()"
                class="w-100 d-grid btn-interested"
            >
                {{ 'waitlist_form_button_label_im_interested' | translate }}
            </vg-button>
        </div>
    </div>
</div>
