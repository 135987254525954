<div class="footer-container">
    <div class="footer-tyc">
        <div class="container">
            <p class="text-tyc">
                {{ 'vg_landing_footer_text-tyc' | translate }}
                <a class="text-a" href="es-mx/?s=tyc">
                    {{ 'vg_landing_footer_text-tyc-a' | translate }}
                </a>
            </p>
        </div>
    </div>
    <div class="container container-max">
        <div class="row">
            <div class="mb-40 d-none d-lg-block"></div>
            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12 order-0 py-0">
                        <p
                            class="text-title text-md-center text-lg-start mb-2 mb-lg-3"
                        >
                            {{
                                'vg_landing_footer_section-payment-title'
                                    | translate
                            }}
                        </p>
                    </div>
                    <div class="col-12 order-1">
                        <div
                            class="d-flex justify-content-start justify-content-md-center justify-content-lg-start mb-tc"
                        >
                            <div>
                                <p class="text-desc mb-lg-1">
                                    {{
                                        'vg_landing_footer_section-payment-subtitle1'
                                            | translate
                                    }}
                                </p>
                                <div class="tc-box">
                                    <img
                                        [src]="image_tc1"
                                        alt="logo mastercard"
                                    />
                                    <img [src]="image_tc2" alt="logo visa" />
                                    <img [src]="image_tc3" alt="logo amex" />
                                </div>
                            </div>
                            <div class="box-text-desc">
                                <p class="text-desc text-desc-padding mb-lg-1">
                                    {{
                                        'vg_landing_footer_section-payment-subtitle2'
                                            | translate
                                    }}
                                </p>
                                <div class="pl-box text-lg-center">
                                    <img [src]="image_pl1" alt="logo paypal" />
                                    <img [src]="image_pl2" alt="logo rappi" />
                                    <img [src]="image_pl3" alt="logo kueski" />
                                    <img
                                        [src]="image_pl4"
                                        alt="logo uplift"
                                        class="pe-0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4 order-3 order-lg-4 mb-rs">
                <p class="text-title mb-lg-4 mt-0 text-md-center text-lg-end">
                    {{ 'vg_landing_footer_section-social-title' | translate }}
                </p>
                <div class="rs-box text-md-center text-lg-end">
                    <div>
                        <a href="{{ link_facebook }}" target="_blank">
                            <img [src]="image_rs1" alt="logo facebook" />
                        </a>
                    </div>
                    <div>
                        <a href="{{ link_instagram }}" target="_blank">
                            <img [src]="image_rs2" alt="logo instagram" />
                        </a>
                    </div>
                    <div>
                        <a href="{{ link_twitter }}" target="_blank">
                            <img [src]="image_rs3" alt="logo twitter" />
                        </a>
                    </div>
                    <div>
                        <a href="{{ link_tiktok }}" target="_blank">
                            <img [src]="image_rs4" alt="logo tiktok" />
                        </a>
                    </div>
                    <div>
                        <a href="{{ link_linkedin }}" target="_blank">
                            <img [src]="image_rs5" alt="logo linkedin" />
                        </a>
                    </div>
                    <div>
                        <a href="{{ link_youtube }}" target="_blank">
                            <img
                                [src]="image_rs6"
                                alt="logo youtube"
                                class="p-lg-0"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-lg-4 order-4 order-lg-3 text-md-center text-lg-center"
            >
                <p class="text-title-apps">
                    {{
                        'vg_landing_footer_section-download-app-title'
                            | translate
                    }}
                </p>
                <div>
                    <div class="d-flex justify-content-md-center mb-apps">
                        <div class="mr-img">
                            <a href="{{ link_appstore }}" target="_blank">
                                <img
                                    [src]="image_st1"
                                    alt="logo app_store"
                                    width="135"
                                />
                            </a>
                        </div>
                        <div>
                            <a href="{{ link_googleplay }}" target="_blank">
                                <img
                                    [src]="image_st2"
                                    alt="logo google_play"
                                    width="135"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-address text-md-center order-5">
                {{ 'vg_landing_footer_section-text-address' | translate }}
            </p>
            <div class="col-12 order-6">
                <div
                    class="d-flex justify-content-center align-items-center flex-wrap"
                >
                    <div>
                        <img [src]="image_pa1" alt="logo iso_9001" />
                    </div>
                    <div class="vr"></div>
                    <div class="d-flex">
                        <div class="d-none d-md-block">
                            <span class="text-powered">
                                {{
                                    'vg_landing_footer_section-powered-text'
                                        | translate
                                }}
                            </span>
                        </div>
                        <div>
                            <img [src]="image_pa2" alt="logo navitaire" />
                        </div>
                    </div>
                    <div class="vr d-none d-md-inline-block"></div>
                    <div class="d-none d-md-inline-block">
                        <img [src]="image_pa3" alt="logo pci" />
                    </div>
                </div>
                <div class="mb-40 d-none d-md-block"></div>
            </div>
            <div class="col-12 mb-40 d-md-none order-7">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="mt-20">
                        <img [src]="image_pa3" alt="logo pci" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
