<header class="position-fixed top-0 start-0">
    <div
        class="header-container mx-auto d-flex justify-content-between align-items-center position-relative w-100"
    >
        <div class="logo">
            <a href="https://www.vivaaerobus.com">
                <img
                    src="/assets/images/logo-header.svg"
                    alt=""
                    class="logo-desk"
            /></a>
            <a href="https://www.vivaaerobus.com">
                <img
                    src="/assets/images/logo-header-mob.svg"
                    alt=""
                    class="logo-mob"
            /></a>
        </div>
        <div class="user-options d-flex align-items-center">
            <div
                class="gift d-flex align-items-center"
                [routerLink]="'/home' | formatUrl | async"
            >
                <img src="/assets/images/gift.svg" alt="" class="gift-icon" />
            </div>
            <div
                class="currency d-flex align-items-center ignore-element-outside"
                (click)="showUserSettings()"
                (keyup.escape)="showUserSettings()"
                tabindex="0"
            >
                <img
                    [src]="
                        currentLanguage === 'es-mx'
                            ? '/assets/images/emojione_flag-for-mexico.svg'
                            : '/assets/images/Fill.svg'
                    "
                    alt=""
                    class="currency-icon"
                />
                <span class="font-family-lato">MXN</span>
            </div>
            <div
                [ngClass]="{ maintenance_status: maintenance_status }"
                aria-hidden="true"
                class="user d-flex align-items-center justify-content-center ignore-element-outside"
            >
                <vg-spinner *ngIf="isLoading"></vg-spinner>
                <ng-container *ngIf="!isLoading">
                    <img
                        src="/assets/images/fa-solid_user.svg"
                        alt=""
                        class="user-icon"
                    />
                    <span class="font-family-lato">
                        {{ (user$ | async)?.name }}
                    </span>
                </ng-container>
            </div>
        </div>

        <div
            [ngClass]="{ 'd-none': !showSettings }"
            (vgClickOutside)="onClickOutside()"
            class="user-settings position-absolute end-0"
        >
            <button
                type="button"
                class="position-absolute border-0 d-flex justify-content-center p-0"
            >
                <img
                    src="/assets/images/close-gray.svg"
                    alt=""
                    (click)="showUserSettings()"
                    (keyup.escape)="showUserSettings()"
                    tabindex="0"
                />
            </button>
            <div class="language-settings d-flex flex-column">
                <div class="country d-flex align-items-center">
                    <img src="/assets/images/language.svg" alt="" />
                    <span class="fw-bold">País</span>
                </div>
                <div
                    (click)="onClickChangeLang('es-mx')"
                    (keydown)="onClickChangeLang('es-mx')"
                    tabindex="1"
                    [ngClass]="currentLanguage === 'es-mx' ? 'selected' : ''"
                    class="language d-flex justify-content-between align-items-center"
                >
                    <span class="fs-6 fw-bold">Español - MXN</span>
                    <img
                        src="/assets/images/emojione_flag-for-mexico.svg"
                        alt=""
                    />
                </div>
                <div
                    (click)="onClickChangeLang('en-us')"
                    (keydown)="onClickChangeLang('en-us')"
                    tabindex="2"
                    [ngClass]="currentLanguage === 'en-us' ? 'selected' : ''"
                    class="language d-flex justify-content-between align-items-center"
                >
                    <span class="fw-bold">Inglés - EUA</span>
                    <img src="/assets/images/Fill.svg" alt="" />
                </div>
            </div>
            <div class="coin-settings d-flex flex-column">
                <div class="coin d-flex align-items-center">
                    <img src="/assets/images/dollar-sign.svg" alt="" />
                    <span class="fw-bold">Moneda</span>
                </div>
                <div
                    class="currency-set d-flex justify-content-between align-items-center"
                >
                    <span class="selected fs-6 fw-bold">Peso Mexicano</span
                    ><span class="currency-selected">MXN</span>
                </div>
            </div>
        </div>

        <div
            [ngClass]="{ 'd-none': !showLogout }"
            (vgClickOutside)="onClickOutside()"
            class="logout-settings position-absolute end-0"
        >
            <button
                type="button"
                class="position-absolute border-0 d-flex justify-content-center p-0"
            >
                <img
                    src="/assets/images/close-gray.svg"
                    alt=""
                    (click)="showLogoutSettings()"
                    (keyup.escape)="showLogoutSettings()"
                    tabindex="0"
                />
            </button>
            <div class="info-settings d-flex flex-column">
                <div
                    tabindex="1"
                    class="info d-flex justify-content-between align-items-center"
                >
                    <span
                        class="fs-6 fw-bold"
                        [routerLink]="'/dashboard/home' | formatUrl | async"
                        >Dashboard</span
                    >
                </div>
                <div
                    (click)="onLogOut($event)"
                    (focus)="onLogOut($event)"
                    (keypress)="onLogOut($event)"
                    class="info d-flex justify-content-between align-items-center"
                    aria-hidden="true"
                >
                    <span class="fs-6 fw-bold">Cerrar Sesión</span>
                </div>
            </div>
        </div>
    </div>
</header>
