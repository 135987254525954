import { WaitListRepository } from '@/core/domain/waitlist/repositories/waitlist.repository';
import {
    PostPreregisterUseCase,
    PostRegisterUseCase,
} from '../../core/domain/waitlist/usecases/postWaitList-loader.usecase';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WaitListImplRepository } from './waitlist-impl.repository';

const waitListPreregisterLoaderUseCaseFactory = (
    WaitListRepository: WaitListRepository,
) => new PostPreregisterUseCase(WaitListRepository);

const waitListRegisterLoaderUseCaseFactory = (
    WaitListRepository: WaitListRepository,
) => new PostRegisterUseCase(WaitListRepository);

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: PostPreregisterUseCase,
            useFactory: waitListPreregisterLoaderUseCaseFactory,
            deps: [WaitListRepository],
        },
        {
            provide: PostRegisterUseCase,
            useFactory: waitListRegisterLoaderUseCaseFactory,
            deps: [WaitListRepository],
        },
        {
            provide: WaitListRepository,
            useClass: WaitListImplRepository,
        },
    ],
})
export class WaitListDataModule {}
