import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { environment as env } from '@/env/environment';
import { inject } from '@angular/core';

export const LanguageGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot,
) => {
    const router = inject(Router);
    const language = route.paramMap.get('language');

    const isValidLanguage = language && env.allowedLanguages.includes(language);

    if (!isValidLanguage) {
        router.navigateByUrl('/es-mx/vivafan');
        return false;
    }

    return isValidLanguage;
};
