import { UseCase } from '@/core/base/use-case';
import { TranslationModel } from '../models/translation.model';
import { TranslationsRepository } from '../repositories/translations.repository';
import { Observable } from 'rxjs';

export type LoaderTranslationsUseCaseInput = {
    lang: 'es-mx' | 'en-us';
};

export class TranslationsLoaderUseCase
    implements UseCase<LoaderTranslationsUseCaseInput, TranslationModel>
{
    constructor(private readonly repository: TranslationsRepository) {}

    execute(
        params: LoaderTranslationsUseCaseInput,
    ): Observable<TranslationModel> {
        return this.repository.load(params.lang);
    }
}
