<div class="container-progress">
    <a
        *ngIf="
            current === 'amount' ||
                current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment';
            else imgDesign
        "
        [routerLink]="'purchase/design' | formatUrl | async"
    >
        <ng-template [ngTemplateOutlet]="imgDesign"></ng-template>
    </a>
    <div
        [ngClass]="
            current === 'design' ||
            current === 'amount' ||
            current === 'shipment' ||
            current === 'personalization' ||
            current === 'payment' ||
            current === 'message' ||
            current === 'confirmation'
                ? 'current'
                : ''
        "
        class="name progress-design-name current"
    >
        {{ 'vg_landing_progress_step-1-label' | translate }}
    </div>
</div>
<div
    [ngClass]="
        current === 'amount' ||
        current === 'shipment' ||
        current === 'personalization' ||
        current === 'payment' ||
        current === 'message' ||
        current === 'confirmation'
            ? 'current'
            : ''
    "
    class="line-progress"
></div>
<div class="container-progress">
    <a
        *ngIf="
            current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment';
            else imgAmount
        "
        [routerLink]="'purchase/amount' | formatUrl | async"
    >
        <ng-template [ngTemplateOutlet]="imgAmount"></ng-template>
    </a>
    <div
        [ngClass]="
            current === 'amount' ||
            current === 'shipment' ||
            current === 'personalization' ||
            current === 'payment' ||
            current === 'message' ||
            current === 'confirmation'
                ? 'current'
                : ''
        "
        class="name progress-design-name"
    >
        {{ 'vg_landing_progress_step-2-label' | translate }}
    </div>
</div>
<div
    [ngClass]="
        current === 'shipment' ||
        current === 'personalization' ||
        current === 'payment' ||
        current === 'message' ||
        current === 'confirmation'
            ? 'current'
            : ''
    "
    class="line-progress"
></div>
<div class="container-progress">
    <a
        *ngIf="
            current === 'personalization' || current === 'payment';
            else imgShipment
        "
        [routerLink]="'purchase/send' | formatUrl | async"
    >
        <ng-template [ngTemplateOutlet]="imgShipment"></ng-template>
    </a>
    <div
        [ngClass]="
            current === 'shipment' ||
            current === 'personalization' ||
            current === 'payment' ||
            current === 'message' ||
            current === 'confirmation'
                ? 'current'
                : ''
        "
        class="name progress-design-name"
    >
        {{ 'vg_landing_progress_step-3-label' | translate }}
    </div>
</div>
<div
    [ngClass]="
        current === 'personalization' ||
        current === 'payment' ||
        current === 'message' ||
        current === 'confirmation'
            ? 'current'
            : ''
    "
    class="line-progress"
></div>
<div class="container-progress">
    <a
        *ngIf="current === 'payment'; else imgPersonalization"
        [routerLink]="'purchase/custom' | formatUrl | async"
    >
        <ng-template [ngTemplateOutlet]="imgPersonalization"></ng-template>
    </a>
    <div
        [ngClass]="
            current === 'personalization' ||
            current === 'payment' ||
            current === 'confirmation'
                ? 'current'
                : ''
        "
        class="name progress-design-name"
    >
        {{ 'vg_landing_progress_step-4-label' | translate }}
    </div>
</div>
<div
    [ngClass]="
        current === 'payment' || current === 'confirmation' ? 'current' : ''
    "
    class="line-progress"
></div>
<div class="container-progress">
    <ng-template [ngTemplateOutlet]="imgPayment"></ng-template>
    <div
        [ngClass]="
            current === 'payment' || current === 'confirmation' ? 'current' : ''
        "
        class="name progress-design-name"
    >
        {{ 'vg_landing_progress_step-5-label' | translate }}
    </div>
</div>
<div
    [ngClass]="current === 'confirmation' ? 'current' : ''"
    class="line-progress"
></div>
<div class="container-progress">
    <div class="container-icon-progress position-relative">
        <img
            [ngClass]="
                current === 'confirmation' ? 'opacity-100 current' : 'opacity-0'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-confirmation-current.svg"
            alt=""
        />
        <img
            [ngClass]="current === 'confirmation' ? 'opacity-0' : 'opacity-100'"
            class="position-absolute top-0 left-0"
            src="assets/images/progress-confirmation.svg"
            alt=""
        />
    </div>
    <div
        [ngClass]="current === 'confirmation' ? 'current' : ''"
        class="name progress-design-name"
    >
        {{ 'vg_landing_progress_step-6-label' | translate }}
    </div>
</div>
<ng-template #imgDesign>
    <div class="container-icon-progress position-relative">
        <img
            class="progress-design current"
            src="assets/images/progress-design.svg"
            alt=""
        />
    </div>
</ng-template>
<ng-template #imgAmount>
    <div class="container-icon-progress position-relative">
        <img
            [ngClass]="
                current === 'amount' ||
                current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-100 current'
                    : 'opacity-0'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-amount-current.svg"
            alt=""
        />
        <img
            [ngClass]="
                current === 'amount' ||
                current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-0'
                    : 'opacity-100'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-amount.svg"
            alt=""
        />
    </div>
</ng-template>
<ng-template #imgShipment>
    <div class="container-icon-progress position-relative">
        <img
            [ngClass]="
                current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-100 current'
                    : 'opacity-0'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-shipment-current.svg"
            alt=""
        />
        <img
            [ngClass]="
                current === 'shipment' ||
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-0'
                    : 'opacity-100'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-shipment.svg"
            alt=""
        />
    </div>
</ng-template>
<ng-template #imgPersonalization>
    <div class="container-icon-progress position-relative">
        <img
            [ngClass]="
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-100 current'
                    : 'opacity-0'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-personalization-current.svg"
            alt=""
        />
        <img
            [ngClass]="
                current === 'personalization' ||
                current === 'payment' ||
                current === 'message' ||
                current === 'confirmation'
                    ? 'opacity-0'
                    : 'opacity-100'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-personalization.svg"
            alt=""
        />
    </div>
</ng-template>
<ng-template #imgPayment>
    <div class="container-icon-progress position-relative">
        <img
            [ngClass]="
                current === 'payment' || current === 'confirmation'
                    ? 'opacity-100 current'
                    : 'opacity-0'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-payment-current.svg"
            alt=""
        />
        <img
            [ngClass]="
                current === 'payment' || current === 'confirmation'
                    ? 'opacity-0'
                    : 'opacity-100'
            "
            class="position-absolute top-0 left-0"
            src="assets/images/progress-payment.svg"
            alt=""
        />
    </div>
</ng-template>
