import { Component } from '@angular/core';

@Component({
    selector: 'vg-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    image_tc1 = 'https://cdn-viva.gruposti.dev/vg/footer/mastercard.svg';
    image_tc2 = 'https://cdn-viva.gruposti.dev/vg/footer/visa.svg';
    image_tc3 = 'https://cdn-viva.gruposti.dev/vg/footer/amex.svg';
    image_pl1 = 'https://cdn-viva.gruposti.dev/vg/footer/paypal.svg';
    image_pl2 = 'https://cdn-viva.gruposti.dev/vg/footer/rappi.svg';
    image_pl3 = 'https://cdn-viva.gruposti.dev/vg/footer/kueski.svg';
    image_pl4 = 'https://cdn-viva.gruposti.dev/vg/footer/uplift.svg';
    image_rs1 = 'https://cdn-viva.gruposti.dev/vg/footer/facebook.svg';
    image_rs2 = 'https://cdn-viva.gruposti.dev/vg/footer/instagram.svg';
    image_rs3 = 'https://cdn-viva.gruposti.dev/vg/footer/twitter.svg';
    image_rs4 = 'https://cdn-viva.gruposti.dev/vg/footer/tiktok.svg';
    image_rs5 = 'https://cdn-viva.gruposti.dev/vg/footer/linkedin.svg';
    image_rs6 = 'https://cdn-viva.gruposti.dev/vg/footer/youtube.svg';
    image_st1 = 'https://cdn-viva.gruposti.dev/vg/footer/app_store.svg';
    image_st2 = 'https://cdn-viva.gruposti.dev/vg/footer/google_play.svg';
    image_pa1 = 'https://cdn-viva.gruposti.dev/vg/footer/iso_9001.svg';
    image_pa2 = 'https://cdn-viva.gruposti.dev/vg/footer/navitaire.svg';
    image_pa3 = 'https://cdn-viva.gruposti.dev/vg/footer/pci.svg';
    link_appstore = 'https://apple.co/3KD2WOu';
    link_googleplay = 'https://bit.ly/viva-app-android';
    link_facebook = 'https://www.facebook.com/VivaAerobus/';
    link_instagram = 'https://www.instagram.com/vivaaerobus/';
    link_twitter = 'https://twitter.com/VivaAerobus';
    link_tiktok = 'https://www.tiktok.com/@vivaaerobus';
    link_linkedin = 'https://mx.linkedin.com/company/vivaaerobus';
    link_youtube = 'https://www.youtube.com/@VivaAerobusMX/';
}
